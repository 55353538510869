function carousel(params) {
    let interval = null;

    const shouldWork = () =>
        params.from && params.to
            ? window.innerWidth > params.from && window.innerWidth < params.to
            : true;

    function swap() {
        return setInterval(() => {
            const items = Array.from(document.querySelectorAll(params.items));
            const last = items[items.length - 1];
            last.style.opacity = 0;
            last.style.width = 0;
            last.parentNode.insertBefore(last, last.parentNode.firstChild);
            setTimeout(() => {
                last.style.removeProperty('opacity');
                last.style.removeProperty('width');
            }, 50);
        }, params.ms);
    }

    const wrapper = document.querySelector(params.parent);
    wrapper.addEventListener('mouseenter', e => stop());
    wrapper.addEventListener('mouseleave', e => start());

    function stop() {
        clearInterval(interval);
        interval = null;
    }

    function start() {
        if (interval || !shouldWork()) {
            return;
        }
        interval = swap();
    }

    function refresh() {
        shouldWork() ? start() : stop();
    }

    if (params.autostart) {
        start();
    }

    return {
        stop,
        start,
        refresh
    };
}

module.exports = carousel;
