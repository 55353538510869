function toggleMobileMenu(state) {
    const hasState = typeof state !== 'undefined';
    const header = document.querySelector('.site-header');
    const condition = hasState ? !!state : !header.classList.contains('nav-open');

    condition ? header.classList.add('nav-open') : header.classList.remove('nav-open');
}

function handleMobileMenu() {
    document.querySelectorAll('.mobile-nav-button').forEach(btn => {
        btn.addEventListener('click', e => toggleMobileMenu());
    });
}

module.exports = {
    toggleMobileMenu,
    handleMobileMenu
};
