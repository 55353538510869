require('../css/layout.css');
require('../css/nav.css');
require('../css/footer.css');
require('../css/slider.css');
require('../css/style.css');
const { scrollTo, scrollToElement, scrollToInit } = require('./func/scroll-to');
const { handleMobileMenu, toggleMobileMenu } = require('./func/mobile-nav');
const slider = require('./func/slider');
const parallax = require('./func/parallax');
const carousel = require('./func/carousel');

window.addEventListener('DOMContentLoaded', run);

async function run(e) {
    handleMobileMenu();

    let scrollInitially = scrollToInit();
    let scrollOffset = 0;

    setTimeout(() => (scrollInitially = false), 600);

    document.body.addEventListener('click', e => {
        if (e.target.classList.contains('scroll-to-link')) {
            e.preventDefault();
            scrollTo(e);
            toggleMobileMenu(false);

            if (!scrollOffset) {
                scrollOffset = 1;
            }
        }
    });

    document.addEventListener('scroll', е => {
        if (scrollInitially) {
            // Hack to prevent this code from triggering when coming to the page with
            // non empty location hash  e.g. https://www.media.bg/#contact
        } else if (!scrollOffset && window.scrollY > 0) {
            e.preventDefault();
            scrollToElement(document.getElementById('top'));
        }

        scrollOffset = window.scrollY;
    });

    const portfolioCarousel = carousel({
        autostart: true,
        from: 768,
        to: 1800,
        items: '#projects .slide-item',
        parent: '#projects .items',
        ms: 4200
    });

    const slider1 = slider({
        wrapper: '#projects .slider-wrapper',
        nav: '#projects .slider-nav',
        item: '#projects .slide-item',
        from: 1,
        to: 767
    });

    const slider2 = slider({
        wrapper: '#values .slider-wrapper',
        nav: '#values .slider-nav',
        item: '#values .slide-item',
        from: 1,
        to: 767
    });

    window.onresize = е => {
        portfolioCarousel.refresh();
        slider1.refresh();
        slider1.slide();
        slider2.refresh();
        slider2.slide();
    };

    const projectImages = document.querySelectorAll('.project-overview img');

    window.onscroll = () => {
        parallax(projectImages);
    };
}
