function scrollTo(e) {
    const link = e.target;
    const id = link.href.slice(link.href.lastIndexOf('#') + 1);
    const element = document.getElementById(id);
    // element.scrollIntoView({ behavior: 'smooth' });
    scrollToElement(element);

    if (link.dataset.tab) {
        document.getElementById(link.dataset.tab).click();
    }
}

function scrollToElement(element, behavior = 'smooth') {
    const scrollTop =
        window.pageYOffset ||
        (document.documentElement || document.body.parentNode || document.body).scrollTop;
    const finalOffset = element.getBoundingClientRect().top + scrollTop;

    window.scrollTo({
        top: finalOffset,
        behavior
    });
}

function scrollToInit() {
    window.scrollTo(0, 0);

    if (location.hash) {
        const id = location.hash.slice(1);
        const element = document.getElementById(id);
        if (element) {
            setTimeout(() => scrollToElement(element), 100);
        }

        return true;
    }

    return false;
}

module.exports = {
    scrollTo,
    scrollToElement,
    scrollToInit
};
