function parallax(elements) {
    elements.forEach(element => {
        const y = window.innerHeight - element.getBoundingClientRect().top;
        if (y > 0) {
            element.style.transform = `translateY(${0.2 * (y - 300)}px)`;
        }
    });
}

module.exports = parallax;
