function slider(params) {
    let activeIndex = 0;
    let touchstartX = 0;
    let touchstartY = 0;
    let touchendX = 0;
    let touchendY = 0;
    let isActive = shouldWork();

    const nav = document.querySelector(params.nav);
    const wrapper = document.querySelector(params.wrapper);
    let slides = document.querySelectorAll(params.item);

    slide();

    wrapper.addEventListener(
        'touchstart',
        event => {
            touchstartX = event.changedTouches[0].screenX;
            touchstartY = event.changedTouches[0].screenY;
        },
        false
    );

    wrapper.addEventListener(
        'touchend',
        event => {
            touchendX = event.changedTouches[0].screenX;
            touchendY = event.changedTouches[0].screenY;
            isActive && handleSwipe(event);
        },
        false
    );

    nav.addEventListener('click', e => {
        if (e.target.classList.contains('nav-item')) {
            activeIndex = Array.from(nav.children).indexOf(e.target);
            isActive & slide();
        }
    });

    function handleSwipe(e) {
        let caseCaptured = false;

        if (touchendX < touchstartX && Math.abs(touchendX - touchstartX) > 32) {
            // Swipe left
            caseCaptured = true;
            if (activeIndex < slides.length - 1) {
                activeIndex++;
                slide();
            }
        } else if (touchendX > touchstartX && Math.abs(touchendX - touchstartX) > 30) {
            // Swipe right
            caseCaptured = true;
            if (!activeIndex) {
                // If user swipes left and the slider is on the first image -> do nothing
                return;
            }

            activeIndex--;
            slide();
        }

        if (caseCaptured) {
            e.preventDefault();
        }
    }

    function slide() {
        if (!isActive) {
            return;
        }

        const idx = activeIndex;

        Array.from(nav.children).forEach((item, i) =>
            idx == i ? item.classList.add('active') : item.classList.remove('active')
        );

        setTimeout(() => {
            if (!slides.length) {
                return;
            }

            const activeSlide = slides[idx];
            const marginLeft = (function calc() {
                return -(
                    activeSlide.offsetWidth * idx +
                    parseInt(window.getComputedStyle(activeSlide).marginRight) * idx
                );
            })();

            slides[0].style.marginLeft = marginLeft + 'px';
        }, 50);
    }

    function shouldWork() {
        return params.from && params.to
            ? window.innerWidth > params.from && window.innerWidth < params.to
            : true;
    }

    function refresh() {
        const isActiveOld = isActive;
        isActive = shouldWork();
        if (!isActive) {
            slides[0].style.removeProperty('margin-left');
        }

        if (isActive && isActive !== isActiveOld) {
            slides = wrapper.querySelectorAll(params.item);
        }
    }

    return {
        refresh,
        slide
    };
}

module.exports = slider;
